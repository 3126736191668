import React, { useEffect } from 'react'
import { environment, TRUSTPILOT_DATA_BUSINESSUNIT_ID, TRUSTPILOT_DATA_TEMPLATE_ID, TRUSTPILOT_URL_EN, TRUSTPILOT_URL_PL } from '../../environment'
import TextLinks from '../../components/TextLinks';

export default function TrustPilotBox(params) {
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log('TrustPilotBox')
    console.log(params)
  }

  useEffect(() => {
    if (!document.querySelector('script[src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"]')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);


  return (
    <section className='reviews-section'>
      <div className="wrapper">
          <div className="container mx-auto px-8 my-10">
            {params.block[params.locale]['Title'] && 
              <h2 className='c-color1'>{params.block[params.locale]['Title']}</h2>
            }
            {params.block[params.locale]['Text'] && 
              <div className='mb-6 max-w-5xl mx-auto reviews-second-text'>
                <TextLinks text={params.block[params.locale]['Text']} line={params.line} />
              </div>
            }
            {params.locale === 'pl' ? 
              <div class="trustpilot-widget" data-locale="pl-PL" data-template-id={TRUSTPILOT_DATA_TEMPLATE_ID} data-businessunit-id={TRUSTPILOT_DATA_BUSINESSUNIT_ID} data-style-height="240px" data-style-width="100%" data-stars="5" data-review-languages="pl">
                <a href={TRUSTPILOT_URL_PL} target="_blank" rel="noopener">Trustpilot</a>
              </div> :
              <div class="trustpilot-widget" data-locale="en-GB" data-template-id={TRUSTPILOT_DATA_TEMPLATE_ID} data-businessunit-id={TRUSTPILOT_DATA_BUSINESSUNIT_ID} data-style-height="240px" data-style-width="100%" data-stars="5" data-review-languages="en">
                <a href={TRUSTPILOT_URL_EN} target="_blank" rel="noopener">Trustpilot</a>
              </div>
            }
          </div>
        </div>
      </section>
  )
}
