import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import { Box, Typography, Divider, FormControlLabel, Radio } from '@mui/material'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

import theme from '../../../styles/MUI-theme'
import Price from './Price'

const RadioInsurancePackage = ({
    id,
    name,
    text,
    description,
    priceNetto,
    priceBrutto,
    values,
    selectedPackageID,
    handleClick,
}) => {
    const { t } = useTranslation()

    const [field] = useField('insurancePackage')

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(values.insurancePackage?.id === id)
    }, [values, id])

    return (
        <Box
            type="button"
            border={`1px solid ${isActive ? 'orange' : '#d1d1d1'}`}
            p={2}
            borderRadius="10px"
            sx={{
                opacity: isActive ? 1 : 0.7,
                transition: 'opacity 0.5s',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
            onClick={() => handleClick(id)}
        >
            <Box display="flex" alignItems="center" pb={1}>
                {isActive ? (
                    <RadioButtonCheckedIcon color="secondary" />
                ) : (
                    <RadioButtonUncheckedIcon color="secondary" />
                )}
                <Typography
                    sx={{
                        fontSize: { xs: 14, lg: 20 },
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        pl: { xs: 1, lg: 2 },
                    }}
                >
                    {name}
                </Typography>
            </Box>
            <Box mt={1}>
                <Typography fontWeight="regular" fontSize={{ xs: 12, lg: 14 }}>
                    {text}
                </Typography>
                <Box my={1}>
                    <Divider />
                </Box>
                <Typography fontWeight="regular" fontSize={{ xs: 12, lg: 14 }} mb={1}>
                    {description}
                </Typography>
            </Box>
            <Box display="flex" marginTop="auto" flexDirection="column">
                <Price priceNetto={priceNetto} priceBrutto={priceBrutto} />
            </Box>
        </Box>
    )
}

export default RadioInsurancePackage
