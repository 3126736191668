import React from 'react'
import { Helmet } from 'react-helmet'
import Spinner from '../../components/Spinner'

const MapComponent = ({ gpsCoords, id = '' }) => {
    return (
        <>
            <Helmet>
                <script type="text/javascript" id="TargeoMapInitialize">
                    {` 
                        var Mapa;
                        function TargeoMapInitialize() {
                            var mapOptions = {
                                container: 'TargeoMapContainer${id}',
                                z: 20,
                                c: {y: ${gpsCoords.lat}, x: ${gpsCoords.lon}},
                                modArguments: {
                                    Ribbon: {controls: []},
                                    Layers: {modes: ['map', 'satellite']},
                                    Buildings3D: {disabled: false, on: false},
                                    POI: {disabled: false, submit: false, correct: true, visible: true},
                                    FTS: {disabled: false},
                                    FindRoute: {disabled: false, pro_prompt: true},
                                    Traffic: {disabled: false, visible: false},
                                }
                            };
                            Mapa = new Targeo.Map(mapOptions);
                            Mapa.listen('onInit', function() {
                                p1 = new Targeo.MapObject.Point(
                                    {y: ${gpsCoords.lat}, x: ${gpsCoords.lon}},
                                    {imageUrl: 'https://mapa.targeo.pl/i/icons/pins/pin-b.png', w: 27, h: 28, coordsAnchor: {x: 9, y: 25}},
                                    'p1',
                                    {name: 'punkt 1', zIndex: 502, draggable: true}
                                ); 
                                Mapa.UOAdd(p1); 
                            })
                            Mapa.initialize();
                        }
                        
                        if(${id === ''}) TargeoMapInitialize();
                        else {
                            var TargeoInterval = setInterval(function(){ 		
                                if (typeof Targeo !== 'undefined'){		
                                    clearInterval(TargeoInterval);		
                                    TargeoMapInitialize()		
                                }		
                            },100);
                        }
                    `}
                </script>
            </Helmet>
        </>
    )
}

export default MapComponent
