import React from 'react'
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import HowItWorksSvg from './HowItWorksSvg'

const HowItWorksRow = ({
    number = '1',
    text = '',
    textBold = '',
    svgName = 'step1',
    iconPosition = 'left',
    isLast,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')) || false

    return isMobile ? (
        <Box my={3}>
            <Box display="flex">
                {iconPosition === 'left' && (
                    <Box mt="130px" mb="-130px">
                        <HowItWorksSvg name="lineVerticalLeft" />
                    </Box>
                )}
                <Box>
                    <Box display="flex" justifyContent="center">
                        <HowItWorksSvg name={svgName} />
                    </Box>
                    <Typography
                        color="secondary"
                        fontSize={35}
                        fontWeight={700}
                        textAlign={iconPosition === 'right' ? 'right' : 'left'}
                    >
                        {number}.
                    </Typography>
                    <Typography
                        color="#ADADAD"
                        textAlign={iconPosition === 'right' ? 'right' : 'left'}
                        my={1}
                        fontSize="13px"
                    >
                        {text}{' '}
                        <Typography component="span" fontWeight={700}>
                            {textBold}
                        </Typography>
                    </Typography>
                </Box>
                {iconPosition === 'right' && (
                    <Box mt="130px" mb="-130px">
                        <HowItWorksSvg name="lineVerticalRight" />
                    </Box>
                )}
            </Box>
        </Box>
    ) : (
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Box
                width="260px"
                p={2}
                display="flex"
                justifyContent="center"
                sx={{
                    visibility: iconPosition === 'right' ? 'hidden' : '',
                }}
            >
                <HowItWorksSvg name={svgName} />
            </Box>
            <Box width="483px">
                <Typography
                    color="secondary"
                    fontSize={35}
                    fontWeight={700}
                    textAlign={iconPosition === 'right' ? 'right' : 'left'}
                >
                    {number}.
                </Typography>
                <Typography color="#ADADAD" textAlign={iconPosition === 'right' ? 'right' : 'left'} my={1}>
                    {text}{' '}
                    <Typography component="span" fontWeight={700}>
                        {textBold}
                    </Typography>
                </Typography>
                {!isLast && (
                    <Box>
                        <HowItWorksSvg name={iconPosition === 'left' ? 'lineToRight' : 'lineToLeft'} />
                    </Box>
                )}
            </Box>
            <Box
                width="260px"
                p={2}
                display="flex"
                justifyContent="flex-end"
                sx={{
                    visibility: iconPosition === 'left' ? 'hidden' : '',
                }}
            >
                <HowItWorksSvg name={svgName} />
            </Box>
        </Box>
    )
}

export default HowItWorksRow
