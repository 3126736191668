import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next' 
import { Alert, Box, Container,  Typography } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'  

import '../../../styles/form.css'

const SummaryLacZeroDeposit = () => {
    const { t, i18n } = useTranslation() 
    const { locale } = useParams() 

    useEffect(() => {
        i18n.changeLanguage(locale)
    }, [locale]) 

    return  (
        <Container>
            <Box p={4} width="100%">
                <Box textAlign="center" mb={2}>
                    <CheckCircle color="secondary" sx={{ fontSize: 40 }} />
                </Box>
                <Typography variant="h1" textAlign="center" fontWeight="bold" fontSize={{ xs: 16, lg: 20 }} mb={5}>
                    {t('RAC.thank_you_page.title')}
                </Typography> 
                <Alert severity="success" variant="standard" sx={{ mb: 1 }}>
                    {t('reservation_confirmation_success')}
                </Alert>
                <Alert severity='info' variant="standard" sx={{ mb: 3 }}>
                    {t('reservation_confirmation_info')}
                </Alert> 
            </Box>
        </Container>
    )  
}

export default SummaryLacZeroDeposit
