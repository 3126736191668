import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { environment, project } from './environment'
import NavMob from './components/Nav/NavMob.js'
import Nav from './components/Nav/Nav.js'
import NavLangOff from './components/Nav/NavLangOff.js'
import LogBox from './components/Nav/LogBox.js'
import NavLangOn from './components/Nav/NavLangOn.js'
import Image from './components/Image.js'
import useWindowDimensions from './Device.js'
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded'
import './styles/header.css'
import LanguageDropdown from './forms/components/LanguageDropdown'

function Header(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Header')
        console.log(params)
    }
    const { device } = useWindowDimensions()
    const { t, i18n } = useTranslation()
    let lang = window.location.pathname.split('/')[1].length > 2 || window.location.pathname.split('/')[1].length === 0 ? 'pl' : window.location.pathname.split('/')[1]
    let langMeta = document.documentElement.lang;
    lang = lang === langMeta ? lang : lang;
    let type = false;
    const pathn = window.location.pathname;
    let lacPaths = environment.lacPaths;
    for (let i = lacPaths.length - 1; i >= 0; --i) {
        if (pathn.indexOf(lacPaths[i]) != -1) {
            type = true
        }
    }
    function OpenMenu() {
        const sidemenu = document.getElementById('sidemenu')
        if (sidemenu?.classList.contains('open')) {
            sidemenu.classList.remove('open')
            CloseSide()
        } else if (!sidemenu.classList.contains('open')) {
            sidemenu.classList.add('open')
        }
    }
    function CloseMenu() {
        const sidemenu = document.getElementById('sidemenu')
        if (sidemenu?.classList.contains('open')) {
            sidemenu.classList.remove('open')
            CloseSide()
        }
    }
    function CloseSide() {
        const sidenav = document.getElementById('sidenav')
        if (sidenav?.classList.contains('show')) {
            sidenav.classList.remove('show')
        }
    }
    if (device !== 'desktop') {
        return (
            <div id="sidemenu">
                <div className="topbar fix-menu b-white">
                    <div className="container">
                        <div className="topbar-container d-flex jc-between f-wrap">
                            <a
                                className="logo-link no-anim w-100 ta-center"
                                href={lang == 'en' ? environment.enMainPage : environment.mainPage}
                                title={t('header.nav.fullname')}
                            >
                                <h1>
                                    <Image src={environment.logoMain} alt={t('header.nav.fullname')} />
                                </h1>
                            </a>
                            <div className="bott-menu d-flex ai-center jc-between w-100"> 
                                {type && <a
                                    id="buttonPhone"
                                    href="tel:+48123000300"
                                    title={t('header.nav.call_us') + ' +48 12 300 03 00'}
                                    className="mr-3 ml-5"
                                >
                                    <LocalPhoneRoundedIcon color="primary"/>
                                </a>}
                                <LanguageDropdown />
                                <div className="menu-button">
                                    <div
                                        id="nav-icon"
                                        className="nav-icon"
                                        onClick={e => {
                                            e.preventDefault()
                                            OpenMenu()
                                        }}
                                    >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                                <div className="log-button d-flex ai-center jc-between"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebar hovered-menu">
                    <NavMob line={params.line} />
                    <div className="down-box">
                        <NavLangOff />
                        <LogBox type={'mobile'} />
                        {type && <a
                            href="tel:+48123000300"
                            title={t('header.nav.call_us') + ' +48 12 300 03 00'}
                            className="btn-link secondary"
                        >
                            {t('header.nav.call_us')} <b>+48&nbsp;123&nbsp;000&nbsp;300</b>
                        </a>}
                        {/* <a href="mailto:mail@mail.mail" title={t('header.nav.write_to')} className="btn-link secondary">
                            {t('header.nav.write_to')}
                        </a> */}
                    </div>
                </div>
                <div
                    className="menu-overlay"
                    onClick={e => {
                        e.preventDefault()
                        CloseMenu()
                    }}
                ></div>
            </div>
        )
    } else {
        return (
            <div id="nav" className="desktop">
                <div className="box-menu b-white">
                    <div className="container pt-0 pb-0 d-flex ai-center jc-between">
                        <nav className="main-nav d-flex jc-between ai-center">
                            <div className="logo-menu d-flex ai-center">
                                <a
                                    className="mr-25 no-anim"
                                    href={lang == 'en' ? environment.enMainPage : environment.mainPage}
                                    title={t('header.nav.fullname')}
                                >
                                    <h1>
                                        <Image src={environment.logoMain} alt={t('header.nav.fullname')} />
                                    </h1>
                                </a>
                            </div>
                            <Nav line={params.line} type="header" />
                        </nav>
                        <div className="add-menu d-flex ai-center">
                            {type && <a
                                href="tel:+48123000300"
                                title={t('header.nav.call_us') + ' +48 12 300 03 00'}
                                className="btn-link secondary"
                            >
                                <b> +48 12 300 03 00</b>
                            </a>}
                            <LogBox type={'desktop'} />
                            <NavLangOff />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header
