import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { Box, Typography, Link, Alert, Divider } from '@mui/material'
import useFormattedPrice from '../../hooks/useFormattedPrice'
import ChooseButton from '../../components/ChooseButton'
import Spinner from '../../components/Spinner'
import Price from './Price'

import theme from '../../../styles/MUI-theme'

const Summary = ({
    carNumber = 1,
    processData,
    priceNetto,
    priceBrutto,
    // depositPriceNetto = 0,
    // depositPriceBrutto = 0,
    insurancePackagePriceNetto = 0,
    insurancePackagePriceBrutto = 0,
    gpsPriceNetto = 0,
    gpsPriceBrutto = 0,
    fuelCardPriceNetto = 0,
    fuelCardPriceBrutto = 0,
    errorPickupLocation,
    errorPickupLocationWithoutStreet,
    serviceName0,
    serviceName1,
    isSubmitting,
}) => {
    const { t } = useTranslation()

    const { values, submitForm } = useFormikContext()

    const fullPriceNetto = useFormattedPrice(
        parseFloat(carNumber * (priceNetto + insurancePackagePriceNetto + gpsPriceNetto + fuelCardPriceNetto)).toFixed(
            2
        )
    )
    const fullPriceBrutto = useFormattedPrice(
        parseFloat(
            carNumber * (priceBrutto + insurancePackagePriceBrutto + gpsPriceBrutto + fuelCardPriceBrutto)
        ).toFixed(2)
    )

    // const depositPriceFormattedNetto = useFormattedPrice(parseFloat(depositPriceNetto).toFixed(2))
    // const depositPriceFormattedGross = useFormattedPrice(parseFloat(depositPriceBrutto).toFixed(2))


    return (
        <Box>
            <Box p={2} border="1px solid #e0e0e0" borderRadius="10px 10px 0 0 ">
                <Box>
                    <Typography fontSize={14} mb={0.2}>
                        {t('LAC.offer_presentation.summary_selected_model')}
                    </Typography>
                    <Typography fontSize={20} fontWeight="bold">
                        {processData?.car_name}
                    </Typography>
                    <Price
                        priceNetto={processData?.offer_rent_rent_price}
                        priceBrutto={processData?.offer_rent_rentgross}
                    />
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box>
                    <Typography fontSize={14}>{t('LAC.offer_presentation.summary_limit')}</Typography>
                    <Typography fontSize={20} fontWeight="bold">
                        {processData?.monthly_km_limit === '0' ? 'PPM (Pay-Per-Mile)' : processData?.monthly_km_limit}
                    </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box>
                    <Typography fontSize={14}>{t('LAC.offer_presentation.summary_rental_period')}</Typography>
                    <Typography fontSize={20} fontWeight="bold">
                        {processData?.rental_period}
                    </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box>
                    <Typography fontSize={14}>{t('LAC.offer_presentation.summary_cars_number')}</Typography>
                    <Typography fontSize={20} fontWeight="bold">
                        {processData?.car_number}
                    </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box>
                    <Typography fontSize={14} mb={1}>
                        {t('LAC.offer_presentation.summary_package')}
                    </Typography>
                    {values.insurancePackage ? (
                        <>
                            <Typography fontSize={20} fontWeight="bold">
                                {values.insurancePackage?.name}
                            </Typography>
                            <Price priceNetto={insurancePackagePriceNetto} priceBrutto={insurancePackagePriceBrutto} />
                        </>
                    ) : (
                        <Alert severity="info" sx={{ mb: 2 }}>
                            {t('LAC.offer_presentation.alert_package')}
                        </Alert>
                    )}
                </Box>
                <>
                    {/* <Box>
                        {values.options?.fuelCard === true ? (
                            <>
                                <Typography fontSize={20} fontWeight="bold">
                                    {t('LAC.offer_presentation.fuel_card')}
                                </Typography>
                                <Price priceNetto={fuelCardPriceNetto} priceBrutto={fuelCardPriceBrutto} />
                                {values.options?.fuelCard && values.options?.gps && <Box mb={2} />}
                            </>
                        ) : (
                            <Alert severity="info">{t('LAC.offer_presentation.alert_fuel_card')}</Alert>
                        )}
                    </Box> */}
                    {values.options?.gps === true && (
                        <>
                            <Divider sx={{ my: 1 }} />
                            <Typography fontSize={14} mb={1}>
                                {t('LAC.offer_presentation.summary_extras')}
                            </Typography>
                            <Typography fontSize={20} fontWeight="bold">
                                {t('LAC.offer_presentation.monitoring')}
                            </Typography>
                            <Price priceNetto={gpsPriceNetto} priceBrutto={gpsPriceBrutto} />
                        </>
                    )}
                </>
            </Box>
            <Box backgroundColor={theme.palette.primary.main} borderRadius="0 0 10px 10px" color="#FAFAFA" p={2}>
                <Typography fontSize={14} color="#FAFAFA">
                    {t('LAC.offer_presentation.summary_cost')}
                    {/* {t('LAC.reservation_deposit')} */}
                </Typography>
                <Typography fontSize={40} color="#FAFAFA" fontWeight="bold">
                    {fullPriceNetto} {t('netto')}
                    {/* {depositPriceFormattedNetto} {t('netto')} */}
                </Typography>
                <Typography fontSize={20} color="#FAFAFA">
                    {fullPriceBrutto} {t('gross')}
                    {/* {depositPriceFormattedGross} {t('gross')} */}
                </Typography>

                {!errorPickupLocation && !errorPickupLocationWithoutStreet && (
                     <ChooseButton id="L3" disabled={isSubmitting} sx={{ mt: 2, width: '100%' }} handleClick={submitForm}>
                     {!isSubmitting ? (
                         t('LAC.offer_presentation.submit')
                     ) : (
                         <Spinner
                             size={23}
                             sx={{
                                 margin: 0,
                             }}
                         />
                     )}
                 </ChooseButton>
                )}
            </Box>
        </Box>
    )
}

export default Summary
