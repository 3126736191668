import React from 'react'
import { Box, Typography } from '@mui/material'
import HowItWorksRow from './HowItWorksRow'

const HowItWorks = () => {
    const locale = window.location.href.includes('en/car-for-company') ? 'en' : 'pl'

    return (
        <Box my={2} mx="auto" maxWidth="970px">
            <Typography variant="h2" color="#7FCCF8" fontSize={35} fontWeight={700} textAlign="left" mb={5}>
                {locale === 'pl' ? 'Jak to działa' : 'How it works'}
            </Typography>
            <HowItWorksRow
                number="1"
                text={
                    locale === 'pl'
                        ? 'Znajdź auto, które spełnia Twoje oczekiwania, wybierz miesięczny limit kilometrów jaki preferujesz i kliknij przycisk'
                        : 'Find a car that meets your expectations, choose your preferred monthly mileage limit, and click the Reserve Online button.'
                }
                textBold={locale === 'pl' ? 'Rezerwuj online' : 'Book online'}
                svgName="step1"
                iconPosition="left"
            />
            <HowItWorksRow
                number="2"
                text={
                    locale === 'pl'
                        ? 'Wskaż datę i miejsce odbioru auta, pakiet ubezpieczenia i opcje dodatkowe'
                        : 'Select the date and location for car pickup, your insurance package, and any additional options.'
                }
                svgName="step2"
                iconPosition="right"
            />
            <HowItWorksRow
                number="3"
                text={
                    locale === 'pl'
                        ? 'Podaj dane kontaktowe a w przypadku klientów biznesowych dane firmy.'
                        : 'Provide your contact details, and for business clients, company information.'
                }
                svgName="step3"
                iconPosition="left"
            />
            <HowItWorksRow
                number="4"
                text={
                    locale === 'pl'
                        ? 'Aby dokończyć rezerwację, opłać zaliczkę przelewem, BLIKIEM, kartą, Google Pay lub Apple Pay. W niektórych przypadkach zaliczka może wynosić 0 zł – wtedy rezerwacja zostanie sfinalizowana automatycznie, bez konieczności dokonywania płatności.'
                        : 'To complete your reservation, pay the advance payment via bank transfer, BLIK, card, Google Pay, or Apple Pay. In some cases, the advance payment may be 0 PLN – in such situations, your reservation will be finalized automatically without the need for payment.'
                }
                svgName="step4"
                iconPosition="right"
                isLast
            />
        </Box>
    )
}

export default HowItWorks
