import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { TextField, Typography, Box, Alert } from '@mui/material'
import { Field, useFormikContext } from 'formik'

import useFetch from '../../hooks/useFetch'
import { API } from '../../api'
import MuiCheckbox from '../../../components/MuiCheckbox'
import ChooseButton from '../../components/ChooseButton'
import CountriesSelect from '../../components/CountriesSelect'
import Spinner from '../../components/Spinner'
import LinkText from '../../components/LinkText'
import ErrorBox from '../ErrorBox'

const KrdVeryfication = ({
    processData,
    haveToCheckKRD,
    setHaveToCheckKRD,
    krdVeryficationIsSuccess,
    setKrdVeryficationIsSuccess,
    userNationality,
    setUserNationality,
    setUserPESEL
}) => {
    const { t } = useTranslation()
    const { id, locale } = useParams()
    const { values, errors, touched } = useFormikContext()

    const { data, loading, error, fetchData } = useFetch()

    useEffect(() => {
        if(processData?.krd_verification_expected === 'yes') { 

            if(values.nationality === 'PL') {
                setHaveToCheckKRD(true)
            }

            if(processData.krd_verification_success === 'yes' || data?.variables?.krd_verification_success === 'yes') {
                setHaveToCheckKRD(false)
                setKrdVeryficationIsSuccess(true)

            }  else if (processData.krd_verification_success === 'no' || data?.variables?.krd_verification_success === 'no') {
                setHaveToCheckKRD(false)
                setKrdVeryficationIsSuccess(false)
            }
        } else {
            setHaveToCheckKRD(false)
        } 
    }, [id, processData, values, data])

    // useEffect(() => {
    //     if (data?.variables?.krd_verification_success === 'yes'){
    //         setKrdVeryficationIsSuccess(true)
    //         setHaveToCheckKRD(false)
    //     }
    //     else if( data?.variables?.krd_verification_success === 'no'){
    //         setKrdVeryficationIsSuccess(false)
    //         setHaveToCheckKRD(false)
    //     } 
    // }, [data])

    const handleCheckKrdVeryfication = async values => {
        fetchData(`${API['KRD_VERYFICATION']}&pid=${id}`, 'POST', values)
    }

    loading && <Spinner />

    error && <ErrorBox error={error} />

    return loading ? (
        <Spinner />
    ) : (
        <>
            <Box mb={4}>
                {krdVeryficationIsSuccess ? (
                    <Alert severity="success" sx={{ mb: 4 }}>
                        {t('RAC.step_four.krd_success')}
                    </Alert>
                ) : (
                    <>
                        {processData?.krd_verification_expected === 'yes' && (
                            <Box mb={2}>
                                <Box mb={3}>
                                    <Typography variant="h2" fontSize={20} fontWeight="bold" textAlign="left">
                                        {t('RAC.step_five.identification')}
                                    </Typography>
                                </Box>
                                <Box mb={2}>
                                    <CountriesSelect
                                        locale={locale}
                                        name={'nationality'}
                                        label={t('RAC.step_four.nationality')}
                                        value={userNationality}
                                        setValue={setUserNationality}
                                        required={haveToCheckKRD}
                                    />
                                </Box>
                            </Box>
                        )}
                        {processData?.krd_verification_expected === 'yes' && values.nationality === 'PL' && (
                            <>
                                <Box mb={2}>
                                    <Field
                                        as={TextField}
                                        name="PESEL"
                                        label="PESEL"
                                        onChange={e => setUserPESEL(e.target.value)}
                                        error={touched.PESEL && Boolean(errors.PESEL)}
                                        helperText={touched.PESEL && errors.PESEL}
                                        fullWidth
                                        required={values.nationality === 'PL'}
                                    />
                                </Box>
                                <MuiCheckbox
                                    name="consentKrd"
                                    label={
                                        <div>
                                            <span style={{ color: 'red' }}>* </span>
                                            <span>{t('LAC.client_data.krd_consent')} </span>
                                            <LinkText href="mailto:wynajem@express.pl">wynajem@express.pl</LinkText>
                                        </div>
                                    }
                                    // label={
                                    //     <>
                                    //         {t('LAC.client_data.krd_consent')}
                                    //         <LinkText href="mailto:wynajem@express.pl">wynajem@express.pl</LinkText>
                                    //     </>
                                    // }
                                    errors={errors['consentKrd']}
                                    touched={touched['consentKrd']}
                                    mb={0}
                                    sx={{ alignItems: 'flex-start' }}
                                    // required={values.nationality === 'PL'}
                                />

                                {values.consentKrd && values.PESEL.length === 11 && (
                                    <ChooseButton
                                        blueBtn
                                        type="button"
                                        sx={{ my: 3 }}
                                        disabled={loading}
                                        handleClick={() => handleCheckKrdVeryfication(values)}
                                    >
                                        {t('btn.check')}
                                    </ChooseButton>
                                )}
                                {loading && <Spinner />}
                            </>
                        )}
                    </>
                )}
            </Box>
        </>
    )
}

export default KrdVeryfication
