export const project = {
    name: 'express',
    fullname: 'Express Car Rental',
    host: window.location.host,
    online: window.location.host.search('cms') >= 0 ? false : true,
}
export const environment = {
    cmsName: 'cms-' + project.name,
    cmsPreName: 'precms.' + project.name,
    cmsProdName: 'cms.' + project.name,
    localhost: 'localhost',
    port: '3004',
    cookieName: 'express',
    devName: 'dev-' + project.name,
    testName: 'test-' + project.name,
    preProdName: 'https://preportal.express.pl',
    prodName: 'https://express.pl',
    devCmsName: 'dev-cms-' + project.name,
    testCmsName: 'test-cms-' + project.name,
    preProdCmsName: 'precms.' + project.name,
    prodCmsName: 'cms-' + project.name,
    siteSites: '/sites/' + project.name,
    siteUrlDev: 'https://dev-' + project.name + '.qrqs.eu',
    siteUrlTest: 'https://test-' + project.name + '.qrqs.eu',
    siteUrlPreProd: 'https://preportal.' + project.name + '.pl',
    siteUrlProd: 'https://express.pl',
    siteCMSDev: 'https://dev-cms-' + project.name + '.qrqs.eu',
    siteCMSTest: 'https://test-cms-' + project.name + '.qrqs.eu',
    siteCMSPreProd: 'https://precms.' + project.name + '.pl',
    siteCMSProd: 'https://cms-' + project.name + '.qrqs.eu',
    cmsPageUrl: '/json/sites/' + project.name,
    page: 'index.html',
    pageJson: 'index.html.json',
    mainPage: '/auto-na-dni/',
    mainPageJson: '/auto-na-dni/index.html.json',
    enMainPage: '/en/car-for-days/',
    enMainPageJson: '/en/car-for-days/index.html.json',
    secondMainPage: '/auto-dla-firmy/',
    secondMainPageJson: '/auto-dla-firmy/index.html.json',
    secondEnMainPage: '/en/car-for-company/',
    secondEnMainPageJson: '/en/car-for-company/index.html.json',
    lacPaths: ['/auto-dla-firmy/','/en/car-for-company/','/auta-na-dluzej/','/en/cars-for-months/','/auto-na-dluzej/','/en/car-for-months/'],
    aboutPage: '/o-nas/profil-firmy/',
    aboutPageJson: '/o-nas/profil-firmy/index.html.json',
    enAboutPage: '/en/about-us/company-profile/',
    enAboutPageJson: '/en/about-us/company-profile/index.html.json',
    error404Page: '/_errors/404.html',
    error404Json: '/_errors/404.html.json',
    navJson: '/_includes/nav/nav-json.json',
    loginUrl: '/authcallback/',
    logoutUrl: '/ajax/User/logout/',
    isLogged: '/ajax/User/isLogged/',
    sitemapJson: '/_includes/nav/sitemap.json',
    categoriesJson: '/_includes/categories/categories.json',
    galleryJson: '/_includes/galleries/list.json',
    searchPath: '',
    searchNewsApi: '/api/jsonws/news/search/',
    searchPage: {'pl':'/wyniki-wyszukiwania/', 'en':'/en/search-results/'},
    registrationPage: {'pl':'/rejestracja/', 'en':'/en/registration/'},
    regCreate: '/ajax/User/create/',
    releasePlaces: {'rac':'/api/rac/releasePlaces', 'lac':'/api/lac/releasePlaces'},
    newsMainLink: '/aktualnosci/',
    searchProm: '/_includes/search/search-main.csv',
    devreCaptchaKey: '',
    testreCaptchaKey: '',
    prodreCaptchaKey: '',
    defaultImage: '/_templates/img/logo/logoEXPRESSnew.png',
    defaultLogo: '/_templates/img/logo/logoEXPRESSnew.png',
    logoMain: '/_templates/img/logo/logoEXPRESSnew.png',
    logoFunduszeEu: {'pl':'/_templates/img/logo/logo-fundusze-eu.webp', 'en':'/_templates/img/logo/logo-fundusze-en.webp'},
    logoFunduszePolska: '/_templates/img/logo/logo-fundusze-polska.png',
    logoNcbr: '/_templates/img/logo/logo-ncbr.png',
    logoUniaFundusze: '/_templates/img/logo/logo-unia-fundusze.png',
    logoHolding: '/_templates/img/logo/logo-Holding.png',
    logoHolding35: '/_templates/img/logo/holding-1-35-lat.jpg',
    logoPZWLP: '/_templates/img/logo/logo-PZWLP.png',
    logoKLJ: {'pl':'/_templates/img/logo/KLJ-2024-pl.webp', 'en':'/_templates/img/logo/KLJ-2024-en.webp'},
    euFunds: {'pl': '/fundusze-europejskie/', 'en': '/en/european-funds/'},
    currencies: '/api/currencies/currencies.json',
    testMapApiKey: '',
    prodMapApiKey: '',
    formApiContact: '/forms-api/contact/getConfig',
    startProcessRAC: '/ajax/Rac/register/',
    processRAC: '/RAC/step2/b/',
    logoEEA: '/_templates/img/logo/EEA_2024_1.webp',
    pickupFolder: {'pl': '/miejsca-odbioru/', 'en': '/en/pick-up-locations/'},
    lacRentUrl: {'pl': 'pl/auto-dla-firmy/wynajem-elastyczny/krok', 'en': 'en/car-for-company/flexible-rental/step'},
    lacRentFlexUrl: {'pl': 'auto-dla-firmy/wynajem-elastyczny/', 'en': 'en/car-for-company/flexible-rental/'},
    racRentUrl: {'pl': 'pl/RAC/', 'en': 'en/RAC/'},
    privacyPolicy: {'pl': '/polityka-prywatnosci/', 'en': '/en/privacy-policy/'},
    frameworkAgreement: {'pl': '/umowa-ramowa/', 'en': '/en/framework-agreement/'},
}

let AUTOMAPA_API_KEY = 'Nzg5OGIyZjBjZTAzMTJlMTdkMTAxZjQ3ZGFhYjllMDNjZjRkNjlkYg=='
let RECAPTCHA = '6Ldlj-kpAAAAAJQJqSVqOwnwm0VS3s2NRCaFywd1'

if(window.location.host.includes('cms.express') || window.location.host.includes('express.pl')) {
    AUTOMAPA_API_KEY = 'Nzg5OGIyZjBjZTAzMTJlMTdkMTAxZjQ3ZGFhYjllMDNjZjRkNjlkYg=='
    // RECAPTCHA = '6Ldlj-kpAAAAAJQJqSVqOwnwm0VS3s2NRCaFywd1'
}
 
export const TRUSTPILOT_DATA_TEMPLATE_ID = '54ad5defc6454f065c28af8b'
export const TRUSTPILOT_DATA_BUSINESSUNIT_ID = '4c5955f600006400050d7e11'
export const TRUSTPILOT_URL_PL = 'https://pl.trustpilot.com/review/express.pl'
export const TRUSTPILOT_URL_EN = 'https://uk.trustpilot.com/review/express.pl'

export const REACT_APP_AUTOMAPA_API_KEY = AUTOMAPA_API_KEY;
export const REACT_APP_RECAPTCHA = RECAPTCHA;